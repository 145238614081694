<template>
    <div class="sld_contract_authen">
        <div class="container" v-if="isReady">
            <h3>{{ !type ? '' : type == 'person' ? L['个人'] : L['企业'] }}{{ title }}</h3>
            <div v-if="!type" class="contract_list flex_row_center_center">
                <div class="contract_item flex_column_center_center">
                    <img class="contract_logo" src="@/assets/contract_person.png" />
                    <p class="contract_title">{{L['个人实名认证']}}</p>
                    <p v-if="authentication.isCertified && authentication.userType && authentication.userType == 1"
                        class="contract_info flex_row_center_center">{{L['已认证']}}<img src="@/assets/contract_isSelf.png" /></p>
                    <p class="contract_none" v-else-if="authentication.userType">{{L['未认证']}}</p>
                    <div class="contract_btn" v-if="authentication.isCertified && authentication.userType == 1"
                        @click="reAuth('person', 1)">{{L['重新认证']}}</div>
                    <div class="contract_btn" v-else @click="gotoAuth('person', 1)">{{L['去认证']}}</div>
                </div>
                <div class="contract_line"></div>
                <div class="contract_item flex_column_center_center">
                    <img class="contract_logo" src="@/assets/contract_company.png" />
                    <p class="contract_title">{{L['企业实名认证']}}</p>
                    <p v-if="authentication.isCertified && authentication.userType && authentication.userType == 2"
                        class="contract_info flex_row_center_center">{{L['已认证']}}<img src="@/assets/contract_isSelf.png" /></p>
                    <p class="contract_none" v-else-if="authentication.userType">{{L['未认证']}}</p>
                    <div class="contract_btn" v-if="authentication.isCertified && authentication.userType == 2"
                        @click="reAuth('company', 2)">{{L['重新认证']}}</div>
                    <div class="contract_btn" v-else @click="gotoAuth('company', 2)">{{L['去认证']}}</div>
                </div>
            </div>
            <div v-else-if="type == 'person'" class="container_main">
                <div class="container_main_step">
                    <div class="group11 flex-row">
                        <template v-for="(item, index) in 3" :key="index">
                            <div class="layer5" :class="{ active: step > index }">
                                <div class="layer8 flex-col">
                                    <span class="word24">{{ index + 1 }}</span>
                                </div>
                                <p v-if="index == 0" class="word25">{{L['完善身份信息']}}</p>
                                <p v-else-if="index == 1" class="word25">{{L['填写验证码']}}</p>
                                <p v-else-if="index == 2" class="word25">{{L['完成认证']}}</p>
                            </div>
                            <div v-if="index < 2" class="layer7 flex-col" :class="{ active: step > index }"></div>
                        </template>
                    </div>
                    <div v-if="step == 1" class="group12">
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['身份证号：']}}</span></div>
                            <input class="layer11" v-model="conInfo.identity" :placeholder="L['请输入身份证号']" maxlength="18"
                                @input="inputVal('errCard')" />
                            <div class="error_info" v-if="errInfo.errCard">{{ errInfo.errCard }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['真实姓名：']}}</span></div>
                            <input class="layer11" v-model="conInfo.userName" :placeholder="L['请输入身份证上姓名']" maxlength="10"
                                @input="inputVal('errName')" />
                            <div class="error_info" v-if="errInfo.errName">{{ errInfo.errName }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['手机号：']}}</span></div>
                            <input class="layer11" v-model="conInfo.userMobile" :placeholder="L['请输入用以上身份证号办理的手机号']"
                                maxlength="11" @input="inputVal('errPhone')" />
                            <div class="error_info" v-if="errInfo.errPhone">{{ errInfo.errPhone }}</div>
                        </div>

                        <div class="group12_btn" @click="nextStep">{{L['下一步']}}</div>
                        <div class="group16">
                            <div class="group16_left flex_row_start_center">
                                <el-checkbox v-model="alreadyCheck"></el-checkbox>
                                <div>{{L['我已阅读并确认']}}<span @click="checkAgreement">{{L['《数字证书使用协议》']}}</span></div>
                            </div>
                            <div class="error_info" v-if="showAleardhCheck">{{L['请勾选后再进行下一步']}}</div>
                        </div>
                    </div>
                    <div v-else-if="step == 2" class="group12">
                        <div class="layer9 flex-row">
                            <div class="layer10"><span>{{L['手机号：']}}</span></div>
                            <div class="layer10">{{ conInfo.userMobile }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['验证码：']}}</span></div>
                            <input class="layer12" v-model="conInfo.smsCode" :placeholder="L['请输入验证码']" maxlength="6"
                                @input="inputVal('errSmscode')" />
                            <div class="layer14" @click="getPhoneCode">{{
                                conInfo.smsTime ? conInfo.smsTime + L['s后可重新发送']
                                    :
                                    L['获取验证码']
                            }}</div>
                            <div class="error_info" v-if="errInfo.errSmscode">{{ errInfo.errSmscode }}</div>
                        </div>
                        <div class="flex_row_start_center">
                            <div class="group12_btn" style="background:#eeeeee;color:#333;" @click="prewStep">{{L['上一步']}}</div>
                            <div class="group12_btn" style="margin-left:15px;" @click="nextStep">{{L['下一步']}}</div>
                        </div>
                    </div>
                    <div v-else-if="step == 3" class="group15 flex-col">
                        <img class="layer16" src="@/assets/prototype/sketch_complete.png" />
                        <p class="layer17">{{L['认证成功!']}}</p>
                        <div class="layer18 flex-row">
                            <p>{{ conInfo.userName }}</p>
                            <img src="@/assets/contract_isSelf.png" />
                        </div>
                        <div class="layer21">{{L['已成功为您发放CA 证书。仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。']}}</div>
                        <div class="layer19"><span>{{ goBackTime }}</span>{{L['s后自动返回至合同管理页']}}</div>
                        <div class="layer20" @click="goBack">{{L['立即返回']}}</div>
                    </div>
                </div>
            </div>
            <div v-else-if="type == 'company'" class="container_main">
                <div class="container_main_step">
                    <div class="group11 flex-row">
                        <template v-for="(item, index) in 3" :key="index">
                            <div class="layer5" :class="{ active: step > index }">
                                <div class="layer8 flex-col">
                                    <span class="word24">{{ index + 1 }}</span>
                                </div>
                                <p v-if="index == 0" class="word25">{{L['完善身份信息']}}</p>
                                <p v-else-if="index == 1" class="word25">{{L['填写验证码']}}</p>
                                <p v-else-if="index == 2" class="word25">{{L['完成认证']}}</p>
                            </div>
                            <div v-if="index < 2" class="layer7 flex-col" :class="{ active: step > index }"></div>
                        </template>
                    </div>
                    <div v-if="step == 1" class="group12 group13">
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['企业名称：']}}</span></div>
                            <input class="layer11" v-model="conInfo.userName" :placeholder="L['请输入企业名称']" maxlength="20"
                                @input="inputVal('errName')" />
                            <div class="error_info" v-if="errInfo.errName">{{ errInfo.errName }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['社会统一信用代码：']}}</span></div>
                            <input class="layer11" v-model="conInfo.regCode" :placeholder="L['请输入社会统一信用代码']" maxlength="18"
                                @input="inputVal('errReg')" />
                            <div class="error_info" v-if="errInfo.errReg">{{ errInfo.errReg }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['法定代表人名称：']}}</span></div>
                            <input class="layer11" v-model="conInfo.legalPerson" :placeholder="L['请输入法定代表人名称']" maxlength="10"
                                @input="inputVal('errLega')" />
                            <div class="error_info" v-if="errInfo.errLega">{{ errInfo.errLega }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['法定代表人身份证号：']}}</span></div>
                            <input class="layer11" v-model="conInfo.identity" placeholder="L['请输入法定代表人身份证号']" maxlength="18"
                                @input="inputVal('errCard')" />
                            <div class="error_info" v-if="errInfo.errCard">{{ errInfo.errCard }}</div>
                        </div>
                        <div class="layer9 flex-row" style="margin-bottom: 8px;">
                            <div class="layer10"><span class="xing">{{L['认证方式：']}}</span></div>
                            <div class="layer10_radio flex_row_start_center">
                                <el-radio v-model="conInfo.authMethod" label="1" @change="changeMethod">{{L['我是法人']}}</el-radio>
                                <el-radio v-model="conInfo.authMethod" label="2" @change="changeMethod">{{L['我是经办人']}}</el-radio>
                            </div>
                        </div>
                        <div class="layer9 flex-row" v-if="conInfo.authMethod == '1'">
                            <div class="layer10"><span class="xing">{{L['法定代表人手机号：']}}</span></div>
                            <input class="layer11" v-model="conInfo.userMobile" :placeholder="L['请输入法定代表人手机号']" maxlength="11"
                                @input="inputVal('errPhone')" />
                            <div class="error_info" v-if="errInfo.errPhone">{{ errInfo.errPhone }}</div>
                        </div>
                        <template v-else>
                            <div class="layer9 flex-row">
                                <div class="layer10"><span class="xing">{{L['经办人名称：']}}</span></div>
                                <input class="layer11" v-model="conInfo.managerName" :placeholder="L['请输入经办人名称']"
                                    maxlength="10" @input="inputVal('errNameJ')" />
                                <div class="error_info" v-if="errInfo.errNameJ">{{ errInfo.errNameJ }}</div>
                            </div>
                            <div class="layer9 flex-row">
                                <div class="layer10"><span class="xing">{{L['经办人身份证号：']}}</span></div>
                                <input class="layer11" v-model="conInfo.managerIdentity" :placeholder="L['请输入经办人身份证号']"
                                    maxlength="18" @input="inputVal('errIdentityJ')" />
                                <div class="error_info" v-if="errInfo.errIdentityJ">{{ errInfo.errIdentityJ }}</div>
                            </div>
                            <div class="layer9 flex-row">
                                <div class="layer10"><span class="xing">{{L['经办人手机号：']}}</span></div>
                                <input class="layer11" v-model="conInfo.userMobile" :placeholder="L['请输入经办人手机号']"
                                    maxlength="11" @input="inputVal('errPhone')" />
                                <div class="error_info" v-if="errInfo.errPhone">{{ errInfo.errPhone }}</div>
                            </div>
                            <div class="layer9 flex-row" style="margin-bottom: 40px;">
                                <div class="layer10"><span class="xing">{{L['授权书：']}}</span></div>
                                <div class="layer10_attorney flex-row">
                                    <input id="attor" type="file" :placeholder="L['请上传文件']" accept=".png,.jpeg,.jpg,.pdf"
                                        @change="uploadFile" />
                                    <template v-if="conInfo.attorneyPowerType == 'jpg'">
                                        <div class="layer10_attorney_bg"
                                            :style="`background-image:url('` + conInfo.attorneyPowerUrl + `')`"></div>
                                        <div class="layer10_attorney_del" @click="delFile">{{L['删除']}}</div>
                                    </template>
                                    <template v-else-if="conInfo.attorneyPowerType == 'pdf'">
                                        <div class="layer10_attorney_name">{{ conInfo.attorneyPowerName }}</div>
                                        <div class="layer10_attorney_del del2" @click="delFile">{{L['删除']}}</div>
                                    </template>
                                    <div class="layer10_attorney_upload flex_column_center_center" v-else>
                                        <span>+</span>
                                        <span>{{L['上传图片']}}</span>
                                    </div>
                                </div>
                                <div class="error_info" v-if="errInfo.errPoert">{{ errInfo.errPoert }}</div>
                            </div>
                        </template>

                        <div class="group12_btn" @click="nextStep">{{L['提交']}}</div>
                        <div class="group16 group166">
                            <div class="group16_left flex_row_start_center">
                                <el-checkbox v-model="alreadyCheck"></el-checkbox>
                                <div>{{L['我已阅读并确认']}}<span @click="checkAgreement">{{L['《数字证书使用协议》']}}</span></div>
                            </div>
                            <div class="error_info" v-if="showAleardhCheck">{{L['请勾选后再进行下一步']}}</div>
                        </div>
                    </div>
                    <div v-else-if="step == 2" class="group12">
                        <div class="layer9 flex-row">
                            <div class="layer10"><span>{{L['手机号：']}}</span></div>
                            <div class="layer10">{{ conInfo.userMobile }}</div>
                        </div>
                        <div class="layer9 flex-row">
                            <div class="layer10"><span class="xing">{{L['验证码：']}}</span></div>
                            <input class="layer12" v-model="conInfo.smsCode" :placeholder="L['请输入验证码']" maxlength="6"
                                @input="inputVal('errSmscode')" />
                            <div class="layer14" @click="getPhoneCode">{{
                                conInfo.smsTime ? conInfo.smsTime + L['s后可重新发送']
                                    :
                                    L['获取验证码']
                            }}</div>
                            <div class="error_info" v-if="errInfo.errSmscode">{{ errInfo.errSmscode }}</div>
                        </div>
                        <div class="flex_row_start_center">
                            <div class="group12_btn" style="background:#eeeeee;color:#333;" @click="prewStep">{{L['上一步']}}</div>
                            <div class="group12_btn" style="margin-left:15px;" @click="nextStep">{{L['下一步']}}</div>
                        </div>
                    </div>
                    <div v-else-if="step == 3" class="group15 flex-col">
                        <img class="layer16" src="@/assets/prototype/sketch_complete.png" />
                        <p class="layer17">{{L['认证成功!']}}</p>
                        <div class="layer18 flex-row">
                            <p>{{ conInfo.userName }}</p>
                            <img src="@/assets/contract_isSelf.png" />
                        </div>
                        <div class="layer21">{{L['已成功为您发放CA 证书。仅在您同意的情况下，您的证书方可被调用签署相关的电子合同等法律文件。']}}</div>
                        <div class="layer19"><span>{{ goBackTime }}</span>{{L['s后自动返回至合同管理页']}}</div>
                        <div class="layer20" @click="goBack">{{L['立即返回']}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
export default {
    name: "AuthenContract",
    components: {
        MemberTitle,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const router = useRouter();
        const store = useStore();
        const isReady = ref(false);
        const step = ref(0); //步骤
        const type = ref(''); //认证类型
        const title = ref('');
        const authentication = ref({}); //认证信息
        const conInfo = reactive({
            identity: '', //个人或法人身份证号码
            userName: '', //用户名称(个人时是身份证件姓名;企业时是企业名称或组织机构名称,必须和证件上登记的姓名一致)
            userMobile: '', //用户手机号
            userType: '', //用户类型(1表示个人，2表示企业或组织机构)
            verifyCode: '', //图形验证码
            verifyKey: '', //图形验证码key
            legalPerson: '', //法人姓名,企业时必填
            regCode: '', //统一社会信用代码,企业时必填
            smsCode: '', //手机验证码
            smsKey: '', //手机验证码key
            smsTime: 0, //手机验证码倒计时
            smsIsSend: false, //手机验证码事件防抖动
            authMethod: '1', //认证方式：1-法人 2-经办人
            managerName: '', //经办人姓名,认证方式为经办人时必填
            managerIdentity: '', //经办人身份证号,认证方式为经办人时必填
            attorneyPower: '', //授权书,认证方式为经办人时必填
            attorneyPowerUrl: '',
            attorneyPowerName: '',
            attorneyPowerType: '',
        })
        let smsTimeout = null;
        const verifyCode = ref('');
        const goBackTime = ref(5);
        let goBackTimeout = null;
        const errInfo = reactive({
            errCard: '',
            errName: '',
            errPhone: '',
            errMsg: '',
            errSmscode: '',
            errReg: '',
            errLega: '',
            errNameJ: '',
            errIdentityJ: '',
            errPoert: '',
        });
        const handleEvent = ref(false); //事件防抖动
        const alreadyCheck = ref(false); //阅读确认
        const showAleardhCheck = ref(false);
        const memberInfo = ref(store.state.memberInfo);

        onMounted(() => {
            title.value = L['实名认证'];
            initData();
        })

        const initData = () => {
            conInfo.identity = ''; //个人或法人身份证号码
            conInfo.userName = ''; //用户名称(个人时是身份证件姓名;企业时是企业名称或组织机构名称,必须和证件上登记的姓名一致)
            conInfo.userMobile = ''; //用户手机号
            conInfo.userType = ''; //用户类型(1表示个人，2表示企业或组织机构)
            conInfo.legalPerson = ''; //法人姓名,企业时必填
            conInfo.regCode = ''; //统一社会信用代码,企业时必填
            getAuth();
        };

        const getCaptcha = () => {
            proxy.$get("v3/captcha/common/getCaptcha").then((res) => {
                if (res.state == 200) {
                    verifyCode.value = 'data:image/png;base64,' + res.data.captcha;
                    conInfo.verifyKey = res.data.key;
                }
            })
        };

        const getAuth = () => { //获取认证信息
            proxy.$get("v3/business/front/contract/certificate/detail").then((res) => {
                isReady.value = true;
                if (res.state == 200) {
                    authentication.value = res.data;
                    if (res.data.isCertified) {
                        gotoAuth(res.data.userType == 1 ? 'person' : 'company', res.data.userType)
                        conInfo.identity = res.data.userIdentity;
                        conInfo.userName = res.data.userName;
                        conInfo.userMobile = res.data.userAccount;
                        if (res.data.authMethod == 2) {
                            conInfo.authMethod = '2';
                            conInfo.managerName = res.data.managerName;
                            conInfo.managerIdentity = res.data.managerIdentity;
                            conInfo.attorneyPowerType = res.data.attorneyPower.indexOf('.pdf') != -1 ? 'pdf' : 'jpg';
                            conInfo.attorneyPower = res.data.attorneyPower;
                            conInfo.attorneyPowerUrl = res.data.attorneyPowerUrl;
                        }
                        conInfo.legalPerson = res.data.legalPerson;
                        conInfo.regCode = res.data.regCode;
                    }
                } else {
                    ElMessage.error(L['获取认证信息失败']);
                }
            })
        };

        const gotoAuth = (info, userType) => {
            type.value = info;
            step.value = 1;
            conInfo.userType = userType;
            if (conInfo.userType == 2 && memberInfo.value.memberType == 3) {
                conInfo.userName = memberInfo.value.enterpriseName ? memberInfo.value.enterpriseName : '';
                conInfo.legalPerson = memberInfo.value.memberTrueName ? memberInfo.value.memberTrueName : '';
                conInfo.userMobile = memberInfo.value.memberMobile ? memberInfo.value.memberMobile : '';
            }
            getCaptcha();
        };

        const reAuth = (info, userType) => {
            type.value = info;
            step.value = 1;
            conInfo.userType = userType;
            getCaptcha();
        };

        const prewStep = () => {
            step.value--;
        };

        const nextStep = () => {
            let flag = true;
            let matchCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            let matchName = /^[\u4e00-\u9fa5]{1,10}$/;
            let matchRegcode = /^[0-9A-Z]{2}\d{6}[0-9A-Z]{10}$/;
            if (type.value == 'person') {
                if (step.value == 1) {
                    if (handleEvent.value) { return; }
                    //个人-完善信息
                    if (!conInfo.identity) {
                        errInfo.errCard = L['请输入身份证号']
                        flag = false;
                    } else if (!matchCard.test(conInfo.identity)) {
                        errInfo.errCard = L['请输入正确的身份证号']
                        flag = false;
                    } else {
                        errInfo.errCard = '';
                    }
                    if (!conInfo.userName) {
                        errInfo.errName = L['请输入真实姓名']
                        flag = false;
                    } else if (!matchName.test(conInfo.userName)) {
                        errInfo.errName = L['请输入正确的真实姓名']
                        flag = false;
                    } else {
                        errInfo.errName = '';
                    }
                    let checkMobile = proxy.$checkMobile(conInfo.userMobile);
                    if (checkMobile !== true) {
                        errInfo.errPhone = checkMobile;
                        flag = false;
                    } else {
                        errInfo.errPhone = '';
                    }
                    if (!alreadyCheck.value) {
                        showAleardhCheck.value = true;
                        flag = false;
                    } else {
                        showAleardhCheck.value = false;
                    }

                    if (!flag) { return; }
                    handleEvent.value = true;
                    let param = {
                        identity: conInfo.identity,
                        userMobile: conInfo.userMobile,
                        userName: conInfo.userName,
                        userType: conInfo.userType,
                        authMethod: 1,

                    }
                    proxy
                        .$post("v3/business/front/contract/certificate/toRegister", param)
                        .then((res) => {
                            handleEvent.value = false;
                            if (res.state == 200) {
                                step.value = 2;
                            } else {
                                ElMessage.warning(res.msg)
                            }
                        })
                } else if (step.value == 2) {
                    if (handleEvent.value) { return; }
                    let checkSmsCode = proxy.$checkSmsCode(conInfo.smsCode)
                    if (checkSmsCode !== true) {
                        errInfo.errSmscode = checkSmsCode;
                        flag = false;
                    } else {
                        errInfo.errSmscode = '';
                    }
                    if (!flag) { return; }
                    handleEvent.value = true;
                    if (smsTimeout) {
                        clearInterval(smsTimeout)
                        smsTimeout = null;
                        conInfo.smsTime = 0;
                        conInfo.smsIsSend = false;
                    }
                    let param = {
                        smsCode: conInfo.smsCode,
                        smsKey: conInfo.smsKey,
                        userMobile: conInfo.userMobile,
                    }
                    proxy
                        .$post("v3/business/front/contract/certificate/codeVerify", param)
                        .then((res) => {
                            if (res.state == 200) {
                                step.value = 3;
                                goBackTime.value = 5;
                                goBackTimeout = setInterval(() => {
                                    if (goBackTime.value > 1) {
                                        goBackTime.value -= 1;
                                    } else {
                                        clearInterval(goBackTimeout)
                                        goBackTimeout = null;
                                        goBack();
                                    }
                                }, 1000)
                            } else {
                                ElMessage.warning(res.msg)
                                handleEvent.value = false;
                            }
                        })
                }
            } else if (type.value == 'company') {
                if (step.value == 1) {
                    if (handleEvent.value) { return; }
                    //企业--完善信息
                    if (!conInfo.userName) {
                        errInfo.errName = L['请输入企业名称'];
                        flag = false;
                    } else {
                        errInfo.errName = '';
                    }
                    if (!conInfo.regCode) {
                        errInfo.errReg = L['请输入社会统一信用代码'];
                        flag = false;
                    } else if (!matchRegcode.test(conInfo.regCode)) {
                        errInfo.errReg = L['请输入正确的社会统一信用代码'];
                        flag = false;
                    } else {
                        errInfo.errReg = '';
                    }
                    if (!conInfo.legalPerson) {
                        errInfo.errLega = L['请输入法定代表人名称'];
                        flag = false;
                    } else {
                        errInfo.errLega = '';
                    }
                    if (!conInfo.identity) {
                        errInfo.errCard = L['请输入法定代表人身份证号']
                        flag = false;
                    } else if (!matchCard.test(conInfo.identity)) {
                        errInfo.errCard = L['请输入正确的法定代表人身份证号']
                        flag = false;
                    } else {
                        errInfo.errCard = '';
                    }
                    let checkMobile = proxy.$checkMobile(conInfo.userMobile);
                    if (checkMobile !== true) {
                        errInfo.errPhone = checkMobile;
                        flag = false;
                    } else {
                        errInfo.errPhone = '';
                    }
                    if (conInfo.authMethod == '2') {
                        if (!conInfo.managerName) {
                            errInfo.errNameJ = L['请输入经办人名称'];
                            flag = false;
                        } else {
                            errInfo.errNameJ = '';
                        }
                        if (!conInfo.managerIdentity) {
                            errInfo.errIdentityJ = L['请输入经办人身份证号']
                            flag = false;
                        } else if (!matchCard.test(conInfo.managerIdentity)) {
                            errInfo.errIdentityJ = L['请输入正确的经办人身份证号']
                            flag = false;
                        } else {
                            errInfo.errIdentityJ = '';
                        }
                        if (!conInfo.attorneyPower) {
                            errInfo.errPoert = L['请上传授权书']
                            flag = false;
                        } else {
                            errInfo.errPoert = '';
                        }
                    }
                    if (!alreadyCheck.value) {
                        showAleardhCheck.value = true;
                        flag = false;
                    } else {
                        showAleardhCheck.value = false;
                    }

                    if (!flag) { return; }
                    handleEvent.value = false;
                    let param = {
                        userName: conInfo.userName,
                        regCode: conInfo.regCode,
                        legalPerson: conInfo.legalPerson,
                        identity: conInfo.identity,
                        userMobile: conInfo.userMobile,
                        userType: conInfo.userType,
                        authMethod: conInfo.authMethod,
                        managerName: conInfo.managerName,
                        managerIdentity: conInfo.managerIdentity,
                        attorneyPower: conInfo.attorneyPower,
                        // verifyCode: conInfo.verifyCode,
                        // verifyKey: conInfo.verifyKey,
                    }
                    proxy
                        .$post("v3/business/front/contract/certificate/toRegister", param)
                        .then((res) => {
                            if (res.state == 200) {
                                step.value = 2;
                            } else {
                                ElMessage.error(res.msg);
                            }
                            handleEvent.value = false;
                        })
                } else if (step.value == 2) {
                    if (handleEvent.value) { return; }
                    let checkSmsCode = proxy.$checkSmsCode(conInfo.smsCode)
                    if (checkSmsCode !== true) {
                        errInfo.errSmscode = checkSmsCode;
                        flag = false;
                    } else {
                        errInfo.errSmscode = '';
                    }
                    if (!flag) { return; }
                    handleEvent.value = true;
                    if (smsTimeout) {
                        clearInterval(smsTimeout)
                        smsTimeout = null;
                        conInfo.smsTime = 0;
                        conInfo.smsIsSend = false;
                    }
                    let param = {
                        smsCode: conInfo.smsCode,
                        smsKey: conInfo.smsKey,
                        userMobile: conInfo.userMobile,
                    }
                    proxy
                        .$post("v3/business/front/contract/certificate/codeVerify", param)
                        .then((res) => {
                            if (res.state == 200) {
                                step.value = 3;
                                goBackTime.value = 5;
                                goBackTimeout = setInterval(() => {
                                    if (goBackTime.value > 1) {
                                        goBackTime.value -= 1;
                                    } else {
                                        clearInterval(goBackTimeout)
                                        goBackTimeout = null;
                                        goBack();
                                    }
                                }, 1000)
                            } else {
                                ElMessage.warning(res.msg)
                                handleEvent.value = false;
                            }
                        })
                }
            }
        };

        const getPhoneCode = () => {
            if (conInfo.smsTime) {
                return;
            } else if (!conInfo.smsIsSend) {
                conInfo.smsIsSend = true;
                let param = {
                    identity: conInfo.identity,
                    userMobile: conInfo.userMobile,
                    userName: conInfo.userName
                }
                proxy
                    .$post("v3/business/front/contract/certificate/getCode", param)
                    .then((res) => {
                        if (res.state == 200) {
                            conInfo.smsKey = res.data;
                            conInfo.smsTime = 60;
                            smsTimeout = setInterval(() => {
                                if (conInfo.smsTime) {
                                    conInfo.smsTime -= 1;
                                } else {
                                    clearInterval(smsTimeout)
                                    smsTimeout = null;
                                    conInfo.smsTime = 0;
                                    conInfo.smsIsSend = false;
                                }
                            }, 1000)
                        } else {
                            conInfo.smsIsSend = false;
                            ElMessage.error(res.msg)
                        }
                    })
            }
        };

        const goBack = () => {
            if (goBackTimeout) {
                clearInterval(goBackTimeout)
                goBackTimeout = null;
            }
            router.back();
        };

        const changeMethod = () => {
            if (conInfo.authMethod == 1) {
                conInfo.userMobile = '';
                conInfo.managerName = '';
                conInfo.managerIdentity = '';
                conInfo.attorneyPower = '';
                errInfo.errNameJ = '';
                errInfo.errIdentityJ = '';
                errInfo.errPoert = '';
                errInfo.errPhone = '';
            } else {
                conInfo.userMobile = '';
                errInfo.errPhone = '';
            }
        };

        const uploadFile = (e) => {
            let file = e.target.files[0];
            if (file.name.indexOf('.pdf') == -1) {
                conInfo.attorneyPowerType = 'jpg';
            } else {
                conInfo.attorneyPowerType = 'pdf';
            }
            conInfo.attorneyPowerName = file.name;
            proxy.$post('v3/oss/front/upload', {
                source: 'authorize',
                file
            }, 'form').then(res => {
                if (res.state == 200) {
                    errInfo.errPoert = '';
                    conInfo.attorneyPower = res.data.path;
                    conInfo.attorneyPowerUrl = res.data.url;
                } else {
                    ElMessage.warning(res.msg);
                }
            })
        };

        const delFile = () => {
            conInfo.attorneyPower = '';
            conInfo.attorneyPowerUrl = '';
            conInfo.attorneyPowerName = '';
            conInfo.attorneyPowerType = '';
            let file = document.getElementById('attor');
            file.value = '';
        };

        const checkAgreement = () => {
            let newWin = router.resolve({
                path: '/contract/agreement'
            })
            window.open(newWin.href, '_blank');
        };

        const inputVal = (key) => {
            if (errInfo[key]) {
                errInfo[key] = '';
            }
        };

        return {
            L, step, title, type, authentication, gotoAuth, reAuth, errInfo, getCaptcha, inputVal,
            verifyCode, conInfo, nextStep, getPhoneCode, goBackTime, goBack, handleEvent, isReady, prewStep,
            alreadyCheck, showAleardhCheck, changeMethod, uploadFile, delFile, memberInfo, checkAgreement
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/style/contract/authen.scss";
</style>
